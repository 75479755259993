/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  ObjectId: any;
}

export interface Accomplishment {
  __typename?: "Accomplishment";
  /** Accomplishment unique ID */
  _id: Scalars["ID"];
  cover: Scalars["String"];
  disable: Scalars["Boolean"];
  quantity: Scalars["Int"];
  reached: Scalars["Int"];
  status: Scalars["Boolean"];
  title: Scalars["String"];
  type: AccomplishmentTypeEnum;
}

export interface AccomplishmentPaginateResponse {
  __typename?: "AccomplishmentPaginateResponse";
  docs: Accomplishment[];
  limit: Scalars["Int"];
  page: Scalars["Int"];
  totalDocs: Scalars["Int"];
  totalPages: Scalars["Int"];
}

export enum AccomplishmentTypeEnum {
  Episode = "episode",
  Premium = "premium",
  Road = "road",
  Streak = "streak",
  Tags = "tags",
}

export interface Achieved {
  __typename?: "Achieved";
  episode?: Maybe<Scalars["Int"]>;
  premium?: Maybe<Scalars["Int"]>;
  road?: Maybe<Scalars["Int"]>;
  streak?: Maybe<Scalars["Int"]>;
  tags?: Maybe<Scalars["Int"]>;
}

export interface Activity {
  __typename?: "Activity";
  /** Activity unique ID */
  _id: Scalars["ID"];
  audio?: Maybe<Scalars["String"]>;
  cover?: Maybe<Scalars["String"]>;
  description: Scalars["String"];
  disable: Scalars["Boolean"];
  finished: Scalars["Int"];
  form?: Maybe<FormActivity[]>;
  friends?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  started: Scalars["Int"];
  status: Scalars["Boolean"];
  type?: Maybe<ActivityTypeAllowed>;
  video?: Maybe<Scalars["String"]>;
}

export interface ActivityPaginateResponse {
  __typename?: "ActivityPaginateResponse";
  docs: Activity[];
  limit: Scalars["Int"];
  page: Scalars["Int"];
  totalDocs: Scalars["Int"];
  totalPages: Scalars["Int"];
}

export enum ActivityTypeAllowed {
  Audio = "audio",
  Form = "form",
  Friends = "friends",
  Video = "video",
}

export interface Activityhistory {
  __typename?: "Activityhistory";
  /** unique ID */
  _id: Scalars["ID"];
  /** Activity */
  activity?: Maybe<Activity>;
  completed: Scalars["Boolean"];
  createdAt: Scalars["DateTime"];
  /** Episode */
  episode?: Maybe<Episode>;
  finishedAt?: Maybe<Scalars["DateTime"]>;
  leadAudio?: Maybe<LeadAudio>;
  leadForm?: Maybe<LeadForm[]>;
  leadFriends?: Maybe<LeadFriends[]>;
  leadVideo?: Maybe<Scalars["String"]>;
  /** Road */
  road: Scalars["ObjectId"];
  type?: Maybe<ActivityTypeAllowed>;
  /** User */
  user: Scalars["ObjectId"];
}

export interface ActivityhistoryPaginateResponse {
  __typename?: "ActivityhistoryPaginateResponse";
  docs: Activityhistory[];
  limit: Scalars["Int"];
  page: Scalars["Int"];
  totalDocs: Scalars["Int"];
  totalPages: Scalars["Int"];
}

export interface Admin {
  __typename?: "Admin";
  /** Admin unique ID */
  _id: Scalars["ID"];
  disable: Scalars["Boolean"];
  /** Admin Email */
  email: Scalars["String"];
  fullName: Scalars["String"];
  permissions: PermissionsMenu[];
  /** Admin phone number */
  phone: Scalars["String"];
  /** Admin phone code */
  phonecode?: Maybe<Scalars["String"]>;
  status: Status;
}

export interface AdminData {
  /** Admin Email */
  email?: InputMaybe<Scalars["String"]>;
  /** Admin Full name */
  fullName?: InputMaybe<Scalars["String"]>;
  /** Admin password 8 - 16 */
  password?: InputMaybe<Scalars["String"]>;
  permissions?: InputMaybe<PermissionsMenu[]>;
  /** Admin phone number */
  phone?: InputMaybe<Scalars["String"]>;
  /** Admin phone code */
  phonecode?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Status>;
}

export interface AdminPaginateResponse {
  __typename?: "AdminPaginateResponse";
  docs: Admin[];
  limit: Scalars["Int"];
  page: Scalars["Int"];
  totalDocs: Scalars["Int"];
  totalPages: Scalars["Int"];
}

export interface AuthResponse {
  __typename?: "AuthResponse";
  accessToken: Scalars["String"];
  email: Scalars["String"];
  fullName: Scalars["String"];
  permissions: PermissionsMenu[];
  phone: Scalars["String"];
  profile: Scalars["String"];
}

export interface AuthUserResponse {
  __typename?: "AuthUserResponse";
  accessToken: Scalars["String"];
  user: User;
}

export interface Content {
  __typename?: "Content";
  content: Scalars["String"];
  type: ContentTypeEnum;
}

export interface ContentInput {
  content: Scalars["String"];
  type: ContentTypeEnum;
}

/** Types Allowed for Content in Episode */
export enum ContentTypeEnum {
  Html = "html",
  Video = "video",
}

export interface CountFormated {
  __typename?: "CountFormated";
  active: Scalars["Int"];
  finished: Scalars["Int"];
}

export interface CreateAccomplishmentInput {
  cover: Scalars["String"];
  disable: Scalars["Boolean"];
  quantity: Scalars["Int"];
  status: Scalars["Boolean"];
  title: Scalars["String"];
  type: AccomplishmentTypeEnum;
}

export interface CreateActivityInput {
  audio?: InputMaybe<Scalars["String"]>;
  cover?: InputMaybe<Scalars["String"]>;
  description: Scalars["String"];
  form?: InputMaybe<FormActivityInput[]>;
  friends?: InputMaybe<Scalars["Int"]>;
  name: Scalars["String"];
  status: Scalars["Boolean"];
  /** Activity type */
  type: Scalars["String"];
  video?: InputMaybe<Scalars["String"]>;
}

export interface CreateAdminInput {
  /** Admin Email */
  email: Scalars["String"];
  /** Admin Full name */
  fullName: Scalars["String"];
  /** Admin password 8 - 16 */
  password: Scalars["String"];
  permissions: PermissionsMenu[];
  /** Admin phone number */
  phone: Scalars["String"];
  /** Admin phone code */
  phonecode: Scalars["String"];
  status: Status;
}

export interface CreateEpisodeInput {
  activity: Scalars["ObjectId"];
  contents: ContentInput[];
  cover: Scalars["String"];
  description: Scalars["String"];
  frases: Array<Scalars["String"]>;
  status: Scalars["Boolean"];
  title: Scalars["String"];
}

export interface CreateNotificationInput {
  active: Scalars["Boolean"];
  date: Scalars["String"];
  membership: NotificationMembership;
  message: Scalars["String"];
  title: Scalars["String"];
}

export interface CreateRoadInput {
  cover: Scalars["String"];
  description: Scalars["String"];
  disable: Scalars["Boolean"];
  episodes: Array<Scalars["ObjectId"]>;
  membership: Membership;
  name: Scalars["String"];
  new: Scalars["Boolean"];
  pillars: PillarInput;
  status: Scalars["Boolean"];
  tags: Array<Scalars["ObjectId"]>;
}

export interface CreateTagInput {
  active: Scalars["Boolean"];
  name: Scalars["String"];
  type: TagType;
}

export interface CreateUserInput {
  /** User country */
  country: Scalars["String"];
  /** User Email */
  email: Scalars["String"];
  /** User fullname */
  fullName: Scalars["String"];
  gender: Gender;
  membership: Membership;
  /** User phone number */
  phone: Scalars["String"];
  /** User phone code */
  phonecode: Scalars["String"];
  /** User pin password */
  pin: Scalars["String"];
  status: Status;
  /** User referral code */
  usedReferralCode?: InputMaybe<Scalars["String"]>;
}

export interface EarnCreditByUser {
  __typename?: "EarnCreditByUser";
  claimPremiumDays: Scalars["Int"];
  completeGoals: Scalars["Int"];
  inviteFriends: Scalars["Int"];
  isClaimPremiumDays: Scalars["Boolean"];
  isCompleteGoals: Scalars["Boolean"];
  isInviteFriends: Scalars["Boolean"];
  isReferalsBuyPremium: Scalars["Boolean"];
  referalsBuyPremium: Scalars["Int"];
}

export interface EarnCreditInfo {
  __typename?: "EarnCreditInfo";
  claimPremiumDays: Scalars["Int"];
  completeGoals: Scalars["Int"];
  inviteFriends: Scalars["Int"];
  referalsBuyPremium: Scalars["Int"];
}

export interface EmotionsFormated {
  __typename?: "EmotionsFormated";
  points: Scalars["Int"];
  tag: Scalars["String"];
}

export interface Episode {
  __typename?: "Episode";
  /** Episode unique ID */
  _id: Scalars["ID"];
  activity: Scalars["String"];
  contents: Content[];
  cover: Scalars["String"];
  description: Scalars["String"];
  disable: Scalars["Boolean"];
  frases: Array<Scalars["String"]>;
  status: Scalars["Boolean"];
  title: Scalars["String"];
}

export interface EpisodePaginateResponse {
  __typename?: "EpisodePaginateResponse";
  docs: Episode[];
  limit: Scalars["Int"];
  page: Scalars["Int"];
  totalDocs: Scalars["Int"];
  totalPages: Scalars["Int"];
}

export interface EpisodesByRoadResponse {
  __typename?: "EpisodesByRoadResponse";
  episodes: Episode[];
}

export interface Favourite {
  __typename?: "Favourite";
  /** User unique ID */
  _id: Scalars["ID"];
  road: Road;
  /** User ID */
  user: Scalars["ObjectId"];
}

export interface FavouritesPaginateResponse {
  __typename?: "FavouritesPaginateResponse";
  docs: Favourite[];
  limit: Scalars["Int"];
  page: Scalars["Int"];
  totalDocs: Scalars["Int"];
  totalPages: Scalars["Int"];
}

export interface FinishedRoadsHistoryInput {
  comment: Scalars["String"];
  rate: Scalars["Int"];
}

export interface Form {
  __typename?: "Form";
  answers?: Maybe<Array<Scalars["String"]>>;
  question?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
}

export interface FormActivity {
  __typename?: "FormActivity";
  /** Activity unique ID */
  _id: Scalars["ID"];
  answers?: Maybe<Array<Scalars["String"]>>;
  question: Scalars["String"];
  /** Form Activity type */
  type: Scalars["String"];
}

export interface FormActivityInput {
  answers?: InputMaybe<Array<Scalars["String"]>>;
  question: Scalars["String"];
  /** Form Activity type */
  type: Scalars["String"];
}

export interface FormHistory {
  answers: Array<Scalars["String"]>;
  question: Scalars["String"];
  type: Scalars["String"];
}

export interface FreemiumInfo {
  __typename?: "FreemiumInfo";
  freemiumDays: Scalars["Int"];
  newLabelDays: Scalars["Int"];
}

/** Gender user */
export enum Gender {
  Female = "FEMALE",
  Male = "MALE",
  Other = "OTHER",
}

/** Gender user */
export enum GenderAllowed {
  Empty = "EMPTY",
  Female = "FEMALE",
  Male = "MALE",
  Other = "OTHER",
}

export interface GetTagsType {
  __typename?: "GetTagsType";
  countries: TagFormated[];
  emotions: TagFormated[];
  gender: TagFormated[];
}

export interface GraphFormated {
  __typename?: "GraphFormated";
  _id: Scalars["String"];
  total: Scalars["Int"];
}

export interface Income {
  __typename?: "Income";
  /** Income unique ID */
  _id: Scalars["ID"];
  amount: Scalars["Float"];
  couteStore: Scalars["Int"];
  currency: Scalars["String"];
  membershipCycle: PaymentCycle;
  payDate: Scalars["String"];
  totalAmount: Scalars["Float"];
  user: UserFormated;
}

export interface IncomePaginateResponse {
  __typename?: "IncomePaginateResponse";
  docs: Income[];
  limit: Scalars["Int"];
  page: Scalars["Int"];
  totalDocs: Scalars["Int"];
  totalPages: Scalars["Int"];
}

export interface InvitationUserInput {
  /** User Email */
  email: Scalars["String"];
  /** User fullname */
  fullName: Scalars["String"];
}

export interface LeadAudio {
  __typename?: "LeadAudio";
  audio?: Maybe<Scalars["String"]>;
  cover?: Maybe<Scalars["String"]>;
}

export interface LeadAudioInput {
  audio: Scalars["String"];
  cover: Scalars["String"];
}

export interface LeadForm {
  __typename?: "LeadForm";
  form?: Maybe<Form[]>;
  id?: Maybe<Scalars["Int"]>;
  response?: Maybe<Scalars["String"]>;
}

export interface LeadFormInput {
  form: FormHistory[];
  id: Scalars["Int"];
  response: Scalars["String"];
}

export interface LeadFriends {
  __typename?: "LeadFriends";
  email?: Maybe<Scalars["String"]>;
  fullName?: Maybe<Scalars["String"]>;
  response?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
}

export interface LeadFriendsInput {
  email?: InputMaybe<Scalars["String"]>;
  fullName?: InputMaybe<Scalars["String"]>;
  response?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
}

export interface LeadsInput {
  leadAudio?: InputMaybe<LeadAudioInput>;
  leadForm?: InputMaybe<LeadFormInput[]>;
  leadFriends?: InputMaybe<LeadFriendsInput[]>;
  leadVideo?: InputMaybe<Scalars["String"]>;
}

export interface LoginAdminInput {
  /** User email */
  email: Scalars["String"];
  /** User password */
  password: Scalars["String"];
}

/** Membership user */
export enum Membership {
  Free = "FREE",
  Freemium = "FREEMIUM",
  Premium = "PREMIUM",
}

/** Membership user */
export enum MembershipAllowed {
  Empty = "EMPTY",
  Free = "FREE",
  Freemium = "FREEMIUM",
  Premium = "PREMIUM",
}

export interface Mutation {
  __typename?: "Mutation";
  claimCredit: Scalars["Boolean"];
  createAccomplishment: Scalars["String"];
  createActivity: Scalars["String"];
  createAdmin: Admin;
  createEpisode: Scalars["String"];
  createNotification: Notification;
  createRoad: Scalars["String"];
  createRoadsHistory: Roadshistory;
  createTag: Tag;
  createUser: User;
  deleteAccomplishment: Scalars["String"];
  deleteAccount: User;
  deleteActivity: Scalars["String"];
  deleteEpisode: Scalars["String"];
  deleteNotification: Scalars["String"];
  deleteRoad: Scalars["String"];
  deleteTag: Scalars["String"];
  disableAdmin: Admin;
  disableUser: User;
  findAndinitActivity: Activityhistory;
  finishedActivity: Scalars["Boolean"];
  finishedRoadsHistory: Scalars["Boolean"];
  getAccomplishmentById: Accomplishment;
  getActivityById: Activity;
  getEpisodeById: Episode;
  getNotificationById: Notification;
  getRankingByTagEmotions: RoadPaginateFilterResponse;
  getRankingRoads?: Maybe<RoadPaginateFilterResponse>;
  getRoadById: Road;
  getTagById: Tag;
  inviteFriend: Activityhistory;
  listAccomplishments: AccomplishmentPaginateResponse;
  listActivities: ActivityPaginateResponse;
  listEpisodes: EpisodePaginateResponse;
  listIncomes: IncomePaginateResponse;
  listNotifications: NotificationPaginateResponse;
  listRoads: RoadPaginateResponse;
  listTags: TagPaginateResponse;
  login: AuthResponse;
  loginSms: AuthUserResponse;
  logout: User;
  randomRoad?: Maybe<Road>;
  resendSms: VerifyUserResponse;
  responseByFriend: Scalars["Boolean"];
  sendInvitation: Scalars["Boolean"];
  toggleFavourite: Scalars["Boolean"];
  updateAccomplishment: Scalars["String"];
  updateActivity: Scalars["String"];
  updateAdmin: Admin;
  updateCreditInfo: Scalars["String"];
  updateEpisode: Scalars["String"];
  updateExtraUser: User;
  updateFeelings: User;
  updateFreemiumInfo: Scalars["String"];
  updateLiveLink: Scalars["String"];
  updateNotification: Scalars["String"];
  updateRoad: Scalars["String"];
  updateRoadsHistory: Roadshistory;
  updateTag: Scalars["String"];
  updateTagsInfo: Scalars["String"];
  updateUser: User;
  updateUserApp: User;
  userInfo: User;
  verifyEmail: VerifyUserResponse;
  verifySms: VerifyUserResponse;
}

export interface MutationCreateAccomplishmentArgs {
  input: CreateAccomplishmentInput;
}

export interface MutationCreateActivityArgs {
  createActivityInput: CreateActivityInput;
}

export interface MutationCreateAdminArgs {
  input: CreateAdminInput;
}

export interface MutationCreateEpisodeArgs {
  createEpisodeInput: CreateEpisodeInput;
}

export interface MutationCreateNotificationArgs {
  input: CreateNotificationInput;
}

export interface MutationCreateRoadArgs {
  createRoadInput: CreateRoadInput;
}

export interface MutationCreateRoadsHistoryArgs {
  reminder: Scalars["String"];
  roadId: Scalars["ObjectId"];
}

export interface MutationCreateTagArgs {
  createTagInput: CreateTagInput;
}

export interface MutationCreateUserArgs {
  input: CreateUserInput;
}

export interface MutationDeleteAccomplishmentArgs {
  accomplishmentId: Scalars["ObjectId"];
}

export interface MutationDeleteActivityArgs {
  activityId: Scalars["ObjectId"];
}

export interface MutationDeleteEpisodeArgs {
  episodeId: Scalars["ObjectId"];
}

export interface MutationDeleteNotificationArgs {
  notificationId: Scalars["ObjectId"];
}

export interface MutationDeleteRoadArgs {
  roadId: Scalars["ObjectId"];
}

export interface MutationDeleteTagArgs {
  tagId: Scalars["ObjectId"];
}

export interface MutationDisableAdminArgs {
  _id: Scalars["ObjectId"];
}

export interface MutationDisableUserArgs {
  _id: Scalars["ObjectId"];
}

export interface MutationFindAndinitActivityArgs {
  activityId: Scalars["ObjectId"];
  episodeId: Scalars["ObjectId"];
  roadId: Scalars["ObjectId"];
}

export interface MutationFinishedActivityArgs {
  historyId: Scalars["ObjectId"];
  input: LeadsInput;
}

export interface MutationFinishedRoadsHistoryArgs {
  historyId: Scalars["ObjectId"];
  input: FinishedRoadsHistoryInput;
}

export interface MutationGetAccomplishmentByIdArgs {
  accomplishmentId: Scalars["ObjectId"];
}

export interface MutationGetActivityByIdArgs {
  activityId: Scalars["ObjectId"];
}

export interface MutationGetEpisodeByIdArgs {
  episodeId: Scalars["ObjectId"];
}

export interface MutationGetNotificationByIdArgs {
  notifactionId: Scalars["ObjectId"];
}

export interface MutationGetRankingByTagEmotionsArgs {
  input: SearchRoadsFiltersInput;
}

export interface MutationGetRankingRoadsArgs {
  input: SearchRoadsFiltersInput;
}

export interface MutationGetRoadByIdArgs {
  roadId: Scalars["ObjectId"];
}

export interface MutationGetTagByIdArgs {
  tagId: Scalars["ObjectId"];
}

export interface MutationInviteFriendArgs {
  historyId: Scalars["ObjectId"];
  index: Scalars["Int"];
  input: LeadsInput;
}

export interface MutationListAccomplishmentsArgs {
  searchAccomplishment: SearchAccomplishmentInput;
}

export interface MutationListActivitiesArgs {
  searchActivity: SearchActivityInput;
}

export interface MutationListEpisodesArgs {
  searchEpisode: SearchEpisodeInput;
}

export interface MutationListIncomesArgs {
  input: SearchIncomesInput;
}

export interface MutationListNotificationsArgs {
  searchNotifications: SearchNotificationsInput;
}

export interface MutationListRoadsArgs {
  searchRoad: SearchRoadsInput;
}

export interface MutationListTagsArgs {
  searchTag: SearchTagsInput;
}

export interface MutationLoginArgs {
  loginAdminInput: LoginAdminInput;
}

export interface MutationLoginSmsArgs {
  input: VerifyTokenInput;
}

export interface MutationResendSmsArgs {
  input: ResendSmsInput;
}

export interface MutationResponseByFriendArgs {
  historyId: Scalars["ObjectId"];
  index: Scalars["Int"];
  input: LeadsInput;
}

export interface MutationSendInvitationArgs {
  input: InvitationUserInput;
}

export interface MutationToggleFavouriteArgs {
  roadId: Scalars["ObjectId"];
}

export interface MutationUpdateAccomplishmentArgs {
  input: UpdateAccomplishmentInput;
}

export interface MutationUpdateActivityArgs {
  updateActivityInput: UpdateActivityInput;
}

export interface MutationUpdateAdminArgs {
  input: UpdateAdminInput;
}

export interface MutationUpdateCreditInfoArgs {
  updateCreditInfo: UpdateCreditData;
}

export interface MutationUpdateEpisodeArgs {
  updateEpisodeInput: UpdateEpisodeInput;
}

export interface MutationUpdateExtraUserArgs {
  input: UpdateExtraUserInput;
}

export interface MutationUpdateFeelingsArgs {
  feelings: Array<Scalars["ObjectId"]>;
}

export interface MutationUpdateFreemiumInfoArgs {
  updateFreemiumData: UpdateFreemiumData;
}

export interface MutationUpdateLiveLinkArgs {
  data: UpdateLiveLinkInput;
}

export interface MutationUpdateNotificationArgs {
  input: UpdateNotificationInput;
}

export interface MutationUpdateRoadArgs {
  updateRoadInput: UpdateRoadInput;
}

export interface MutationUpdateRoadsHistoryArgs {
  reminder: Scalars["String"];
  roadId: Scalars["ObjectId"];
}

export interface MutationUpdateTagArgs {
  updateTagInput: UpdateTagInput;
}

export interface MutationUpdateTagsInfoArgs {
  updateTagsInfo: UpdateTagsData;
}

export interface MutationUpdateUserArgs {
  input: UpdateUserInput;
}

export interface MutationUpdateUserAppArgs {
  input: CreateUserInput;
}

export interface MutationVerifyEmailArgs {
  input: VerifyEmailInput;
}

export interface MutationVerifySmsArgs {
  input: VerifySmsInput;
}

export interface MyFavouritesInput {
  page: Scalars["Int"];
  perPage: Scalars["Int"];
}

export interface Notification {
  __typename?: "Notification";
  /** Notification unique ID */
  _id: Scalars["ID"];
  active: Scalars["Boolean"];
  date: Scalars["String"];
  membership: NotificationMembership;
  message: Scalars["String"];
  sended: Scalars["Boolean"];
  sendedDate: Scalars["String"];
  title: Scalars["String"];
}

export enum NotificationMembership {
  All = "ALL",
  Free = "FREE",
  Freemium = "FREEMIUM",
  Premium = "PREMIUM",
}

export enum NotificationMembershipAllowed {
  All = "ALL",
  Empty = "EMPTY",
  Free = "FREE",
  Freemium = "FREEMIUM",
  Premium = "PREMIUM",
}

export interface NotificationPaginateResponse {
  __typename?: "NotificationPaginateResponse";
  docs: Notification[];
  limit: Scalars["Int"];
  page: Scalars["Int"];
  totalDocs: Scalars["Int"];
  totalPages: Scalars["Int"];
}

export enum PaymentCycle {
  Anual = "Anual",
  Mes = "Mes",
}

export enum PermissionAllowed {
  Achievements = "ACHIEVEMENTS",
  Admins = "ADMINS",
  Config = "CONFIG",
  Dashboard = "DASHBOARD",
  Empty = "EMPTY",
  Income = "INCOME",
  Notification = "NOTIFICATION",
  Ranking = "RANKING",
  Roads = "ROADS",
  Users = "USERS",
}

/** Permissions to access admin modules */
export enum PermissionsMenu {
  Achievements = "ACHIEVEMENTS",
  Admins = "ADMINS",
  Config = "CONFIG",
  Dashboard = "DASHBOARD",
  Income = "INCOME",
  Notification = "NOTIFICATION",
  Ranking = "RANKING",
  Roads = "ROADS",
  Users = "USERS",
}

export interface PhraseDesignImages {
  __typename?: "PhraseDesignImages";
  /** PhraseDesignImages unique ID */
  _id: Scalars["ID"];
  name: Scalars["String"];
  uri: Scalars["String"];
}

export enum PillarAllowed {
  Emotions = "EMOTIONS",
  Empty = "EMPTY",
  Purpose = "PURPOSE",
  Relations = "RELATIONS",
  SelfEsteem = "SELF_ESTEEM",
  Transformation = "TRANSFORMATION",
}

export interface PillarInput {
  emotions: Scalars["Int"];
  purpose: Scalars["Int"];
  relations: Scalars["Int"];
  selfEsteem: Scalars["Int"];
  transformation: Scalars["Int"];
}

export interface Pillars {
  __typename?: "Pillars";
  emotions: Scalars["Int"];
  purpose: Scalars["Int"];
  relations: Scalars["Int"];
  selfEsteem: Scalars["Int"];
  transformation: Scalars["Int"];
}

export interface Query {
  __typename?: "Query";
  countRoadsHistory: RoadshistoryCounts;
  earnCreditByUser: EarnCreditByUser;
  episodesByRoad: EpisodesByRoadResponse;
  findById: Admin;
  findByIdActivityHistory: Activityhistory;
  findEmotionsRecord?: Maybe<EmotionsFormated[]>;
  findGraph?: Maybe<GraphFormated[]>;
  findPaginateByPillar?: Maybe<RoadshistoryPaginateResponse>;
  findUserById: User;
  getFullSettings?: Maybe<SettingsFormated>;
  getRoadsHistoryByUser?: Maybe<Roadshistory>;
  isMyFavourite: Scalars["Boolean"];
  listAccomplishmentsByUser?: Maybe<Accomplishment[]>;
  listActivityhistoryFinished?: Maybe<ActivityhistoryPaginateResponse>;
  listAdmin: AdminPaginateResponse;
  listPhrasesDesign?: Maybe<PhraseDesignImages[]>;
  listRoadsHistory?: Maybe<RoadshistoryPaginateResponse>;
  listRoadsHistoryFinished?: Maybe<RoadshistoryPaginateResponse>;
  listUser: UserPaginateResponse;
  me: Admin;
  myFavourites: FavouritesPaginateResponse;
  recommendedForMy: RoadPaginateResponse;
  roadsByPillar: RoadPaginateResponse;
}

export interface QueryEpisodesByRoadArgs {
  roadId: Scalars["ObjectId"];
}

export interface QueryFindByIdArgs {
  _id: Scalars["ObjectId"];
}

export interface QueryFindByIdActivityHistoryArgs {
  historyId: Scalars["ObjectId"];
}

export interface QueryFindPaginateByPillarArgs {
  input: SearchByPillarRoadsHistoryInput;
}

export interface QueryFindUserByIdArgs {
  _id: Scalars["ObjectId"];
}

export interface QueryGetRoadsHistoryByUserArgs {
  roadId: Scalars["ObjectId"];
}

export interface QueryIsMyFavouriteArgs {
  roadId: Scalars["ObjectId"];
}

export interface QueryListActivityhistoryFinishedArgs {
  input: SearchActivityhistoryInput;
}

export interface QueryListAdminArgs {
  searchAdmin: SearchAdminInput;
}

export interface QueryListRoadsHistoryArgs {
  input: SearchRoadsHistoryInput;
}

export interface QueryListRoadsHistoryFinishedArgs {
  input: SearchRoadsHistoryInput;
}

export interface QueryListUserArgs {
  searchUsers: SearchUserInput;
}

export interface QueryMyFavouritesArgs {
  input: MyFavouritesInput;
}

export interface QueryRecommendedForMyArgs {
  input: RecommendedInput;
  tags: Array<Scalars["ObjectId"]>;
}

export interface QueryRoadsByPillarArgs {
  input: RecommendedByPillarInput;
}

export interface RecommendedByPillarInput {
  page: Scalars["Int"];
  perPage: Scalars["Int"];
  pillar: PillarAllowed;
  q?: InputMaybe<Scalars["String"]>;
}

export interface RecommendedInput {
  page: Scalars["Int"];
  perPage: Scalars["Int"];
}

export interface ResendSmsInput {
  /** User Phone Number */
  phone: Scalars["String"];
  /** Platform app OS */
  platform: Scalars["String"];
}

export interface Road {
  __typename?: "Road";
  /** Road unique ID */
  _id: Scalars["ID"];
  arrayMembership: Membership[];
  cover: Scalars["String"];
  createdAt: Scalars["DateTime"];
  description: Scalars["String"];
  disable: Scalars["Boolean"];
  episodes: Array<Scalars["ObjectId"]>;
  finished: Scalars["Int"];
  likes: Scalars["Float"];
  membership: Membership;
  name: Scalars["String"];
  new: Scalars["Boolean"];
  pillars: Pillars;
  rate: Scalars["Float"];
  started: Scalars["Int"];
  status: Scalars["Boolean"];
  tags: Array<Scalars["ObjectId"]>;
}

export interface RoadExtended {
  __typename?: "RoadExtended";
  /** Road unique ID */
  _id: Scalars["ID"];
  arrayMembership: Membership[];
  cover: Scalars["String"];
  createdAt: Scalars["DateTime"];
  description: Scalars["String"];
  disable: Scalars["Boolean"];
  episodes: Array<Scalars["ObjectId"]>;
  finished: Scalars["Int"];
  likes: Scalars["Float"];
  membership: Membership;
  name: Scalars["String"];
  new: Scalars["Boolean"];
  pillars: Pillars;
  rate: Scalars["Float"];
  started: Scalars["Int"];
  status: Scalars["Boolean"];
  tags: Tag[];
}

export interface RoadPaginateFilterResponse {
  __typename?: "RoadPaginateFilterResponse";
  docs: RoadExtended[];
  limit: Scalars["Int"];
  page: Scalars["Int"];
  totalDocs: Scalars["Int"];
  totalPages: Scalars["Int"];
  totalPillars: Pillars;
}

export interface RoadPaginateResponse {
  __typename?: "RoadPaginateResponse";
  docs: Road[];
  limit: Scalars["Int"];
  page: Scalars["Int"];
  totalDocs: Scalars["Int"];
  totalPages: Scalars["Int"];
}

export interface Roadshistory {
  __typename?: "Roadshistory";
  /** unique ID */
  _id: Scalars["ID"];
  comment?: Maybe<Scalars["String"]>;
  completed: Scalars["Boolean"];
  createdAt: Scalars["DateTime"];
  /** Current Episode */
  currentEpisode: Scalars["Float"];
  finishedAt?: Maybe<Scalars["DateTime"]>;
  pillars: Pillars;
  rate?: Maybe<Scalars["Int"]>;
  reminder: Scalars["String"];
  /** Road */
  road: Road;
  tagspoints?: Maybe<TagsPoints[]>;
  /** User */
  user: Scalars["ObjectId"];
}

export interface RoadshistoryCounts {
  __typename?: "RoadshistoryCounts";
  emotions: CountFormated;
  purpose: CountFormated;
  relations: CountFormated;
  selfEsteem: CountFormated;
  transformation: CountFormated;
}

export interface RoadshistoryFormated {
  __typename?: "RoadshistoryFormated";
  /** unique ID */
  _id: Scalars["ID"];
  completed: Scalars["Boolean"];
  createdAt: Scalars["DateTime"];
  /** Current Episode */
  currentEpisode: Scalars["Float"];
  finishedAt: Scalars["DateTime"];
  pillars: Pillars;
  reminder: Scalars["String"];
  /** Road */
  road: Road;
  /** User */
  user: Scalars["ObjectId"];
}

export interface RoadshistoryPaginateResponse {
  __typename?: "RoadshistoryPaginateResponse";
  docs: RoadshistoryFormated[];
  limit: Scalars["Int"];
  page: Scalars["Int"];
  totalDocs: Scalars["Int"];
  totalPages: Scalars["Int"];
}

export interface SearchAccomplishmentInput {
  page: Scalars["Int"];
  perPage: Scalars["Int"];
  q: Scalars["String"];
  sort: Scalars["String"];
  sortColumn: Scalars["String"];
}

export interface SearchActivityInput {
  page: Scalars["Int"];
  perPage: Scalars["Int"];
  q: Scalars["String"];
  sort: Scalars["String"];
  sortColumn: Scalars["String"];
  status: StatusAllowed;
  /** Activity type */
  type: Scalars["String"];
}

export interface SearchActivityhistoryInput {
  page: Scalars["Int"];
  perPage: Scalars["Int"];
  /** Activity type */
  type: Scalars["String"];
}

export interface SearchAdminInput {
  page: Scalars["Int"];
  perPage: Scalars["Int"];
  permissions: PermissionAllowed;
  q: Scalars["String"];
  sort: Scalars["String"];
  sortColumn: Scalars["String"];
  status: StatusAllowed;
}

export interface SearchByPillarRoadsHistoryInput {
  completed: Scalars["Boolean"];
  page: Scalars["Int"];
  perPage: Scalars["Int"];
  pillar: Scalars["String"];
}

export interface SearchEpisodeInput {
  /** Episode Activity */
  activity: Scalars["String"];
  page: Scalars["Int"];
  perPage: Scalars["Int"];
  q: Scalars["String"];
  sort: Scalars["String"];
  sortColumn: Scalars["String"];
  status: StatusAllowed;
}

export interface SearchIncomesInput {
  all?: InputMaybe<Scalars["Boolean"]>;
  dates: Array<Scalars["String"]>;
  page: Scalars["Int"];
  perPage: Scalars["Int"];
  q: Scalars["String"];
  sort: Scalars["String"];
  sortColumn: Scalars["String"];
}

export interface SearchNotificationsInput {
  membership: NotificationMembershipAllowed;
  page: Scalars["Int"];
  perPage: Scalars["Int"];
  q: Scalars["String"];
  sort: Scalars["String"];
  sortColumn: Scalars["String"];
}

export interface SearchRoadsFiltersInput {
  all?: InputMaybe<Scalars["Boolean"]>;
  dates: Array<Scalars["String"]>;
  page: Scalars["Int"];
  perPage: Scalars["Int"];
  pillar: PillarAllowed;
  q: Scalars["String"];
  sort: Scalars["String"];
  sortColumn: Scalars["String"];
}

export interface SearchRoadsHistoryInput {
  page: Scalars["Int"];
  perPage: Scalars["Int"];
}

export interface SearchRoadsInput {
  membership: MembershipAllowed;
  page: Scalars["Int"];
  perPage: Scalars["Int"];
  pillar: PillarAllowed;
  q: Scalars["String"];
  sort: Scalars["String"];
  sortColumn: Scalars["String"];
  status: StatusAllowed;
}

export interface SearchTagsInput {
  page: Scalars["Int"];
  perPage: Scalars["Int"];
  q: Scalars["String"];
  sort: Scalars["String"];
  sortColumn: Scalars["String"];
  status: StatusAllowed;
  type: TagTypeAllowed;
}

export interface SearchUserInput {
  gender: GenderAllowed;
  membership: MembershipAllowed;
  page: Scalars["Int"];
  perPage: Scalars["Int"];
  q: Scalars["String"];
  sort: Scalars["String"];
  sortColumn: Scalars["String"];
  status: StatusAllowed;
}

export interface SettingsFormated {
  __typename?: "SettingsFormated";
  earnCreditInfo: EarnCreditInfo;
  freemiumInfo: FreemiumInfo;
  isLive: Scalars["Boolean"];
  liveLink: Scalars["String"];
  tags: GetTagsType;
}

/** Status user */
export enum Status {
  Active = "ACTIVE",
  Inactive = "INACTIVE",
}

export enum StatusAllowed {
  Active = "ACTIVE",
  Empty = "EMPTY",
  Inactive = "INACTIVE",
}

export interface Tag {
  __typename?: "Tag";
  /** Tag unique ID */
  _id: Scalars["ID"];
  active: Scalars["Boolean"];
  name: Scalars["String"];
  type: TagType;
}

export interface TagFormated {
  __typename?: "TagFormated";
  /** Tag unique ID */
  _id: Scalars["ID"];
  name: Scalars["String"];
}

export interface TagPaginateResponse {
  __typename?: "TagPaginateResponse";
  docs: Tag[];
  limit: Scalars["Int"];
  page: Scalars["Int"];
  totalDocs: Scalars["Int"];
  totalPages: Scalars["Int"];
}

export enum TagType {
  Countries = "countries",
  Emotions = "emotions",
  Gender = "gender",
}

export enum TagTypeAllowed {
  Empty = "EMPTY",
  Countries = "countries",
  Emotions = "emotions",
  Gender = "gender",
}

export interface TagsPoints {
  __typename?: "TagsPoints";
  /** Current point */
  point: Scalars["Float"];
  /** Tag */
  tag: Tag;
}

export interface UpdateAccomplishmentData {
  cover?: InputMaybe<Scalars["String"]>;
  disable?: InputMaybe<Scalars["Boolean"]>;
  quantity?: InputMaybe<Scalars["Int"]>;
  status?: InputMaybe<Scalars["Boolean"]>;
  title?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<AccomplishmentTypeEnum>;
}

export interface UpdateAccomplishmentInput {
  _id: Scalars["ObjectId"];
  data: UpdateAccomplishmentData;
}

export interface UpdateActivityData {
  audio?: InputMaybe<Scalars["String"]>;
  cover?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  form?: InputMaybe<FormActivityInput[]>;
  friends?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["Boolean"]>;
  /** Activity type */
  type?: InputMaybe<Scalars["String"]>;
  video?: InputMaybe<Scalars["String"]>;
}

export interface UpdateActivityInput {
  _id: Scalars["ObjectId"];
  data: UpdateActivityData;
}

export interface UpdateAdminInput {
  _id: Scalars["ID"];
  data: AdminData;
}

export interface UpdateCreditData {
  claimPremiumDays?: InputMaybe<Scalars["Int"]>;
  completeGoals?: InputMaybe<Scalars["Int"]>;
  inviteFriends?: InputMaybe<Scalars["Int"]>;
  referalsBuyPremium?: InputMaybe<Scalars["Int"]>;
}

export interface UpdateEpisodeData {
  activity?: InputMaybe<Scalars["ObjectId"]>;
  contents?: InputMaybe<ContentInput[]>;
  cover?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  frases?: InputMaybe<Array<Scalars["String"]>>;
  status?: InputMaybe<Scalars["Boolean"]>;
  title?: InputMaybe<Scalars["String"]>;
}

export interface UpdateEpisodeInput {
  _id: Scalars["ObjectId"];
  data: UpdateEpisodeData;
}

export interface UpdateExtraUserInput {
  /** Expo token push notifications */
  expoToken: Scalars["String"];
  /** Time Zone App */
  timezone: Scalars["String"];
}

export interface UpdateFreemiumData {
  freemiumDays?: InputMaybe<Scalars["Int"]>;
  newLabelDays?: InputMaybe<Scalars["Int"]>;
}

export interface UpdateLiveLinkInput {
  isLive: Scalars["Boolean"];
  liveLink: Scalars["String"];
}

export interface UpdateNotificationData {
  active?: InputMaybe<Scalars["Boolean"]>;
  date?: InputMaybe<Scalars["String"]>;
  membership?: InputMaybe<NotificationMembership>;
  message?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
}

export interface UpdateNotificationInput {
  _id: Scalars["ObjectId"];
  data: UpdateNotificationData;
}

export interface UpdateRoadData {
  cover?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  disable?: InputMaybe<Scalars["Boolean"]>;
  episodes?: InputMaybe<Array<Scalars["ObjectId"]>>;
  membership?: InputMaybe<Membership>;
  name?: InputMaybe<Scalars["String"]>;
  new?: InputMaybe<Scalars["Boolean"]>;
  pillars?: InputMaybe<PillarInput>;
  status?: InputMaybe<Scalars["Boolean"]>;
  tags?: InputMaybe<Array<Scalars["ObjectId"]>>;
}

export interface UpdateRoadInput {
  _id: Scalars["ObjectId"];
  data: UpdateRoadData;
}

export interface UpdateTagData {
  active?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<TagType>;
}

export interface UpdateTagInput {
  _id: Scalars["ObjectId"];
  data: UpdateTagData;
}

export interface UpdateTagsData {
  countries?: InputMaybe<Array<Scalars["ObjectId"]>>;
  emotions?: InputMaybe<Array<Scalars["ObjectId"]>>;
  gender?: InputMaybe<Array<Scalars["ObjectId"]>>;
}

export interface UpdateUserInput {
  _id: Scalars["ID"];
  data: UserData;
}

export interface User {
  __typename?: "User";
  /** User unique ID */
  _id: Scalars["ID"];
  achieved?: Maybe<Achieved>;
  claimcredit?: Maybe<Scalars["Boolean"]>;
  /** User country */
  country?: Maybe<Scalars["String"]>;
  disable: Scalars["Boolean"];
  /** User Email */
  email?: Maybe<Scalars["String"]>;
  expiredFreemium?: Maybe<Scalars["DateTime"]>;
  /** Expo token push notifications */
  expoToken?: Maybe<Scalars["String"]>;
  feelings?: Maybe<Tag[]>;
  /** User fullname */
  fullName?: Maybe<Scalars["String"]>;
  fullRecord: Scalars["Boolean"];
  gender?: Maybe<Gender>;
  membership: Membership;
  /** User phone number */
  phone?: Maybe<Scalars["String"]>;
  /** User phone code */
  phonecode?: Maybe<Scalars["String"]>;
  /** User pin password */
  pin?: Maybe<Scalars["String"]>;
  /** Platform app OS */
  platform?: Maybe<Scalars["String"]>;
  /** MY referral code */
  referralCode: Scalars["String"];
  status: Status;
  streakDate?: Maybe<Scalars["DateTime"]>;
  /** Time Zone App */
  timezone?: Maybe<Scalars["String"]>;
  /** User referral code */
  usedReferralCode?: Maybe<Scalars["String"]>;
}

export interface UserData {
  /** User country */
  country?: InputMaybe<Scalars["String"]>;
  /** User Email */
  email?: InputMaybe<Scalars["String"]>;
  /** User fullname */
  fullName?: InputMaybe<Scalars["String"]>;
  gender?: InputMaybe<Gender>;
  membership?: InputMaybe<Membership>;
  /** User phone number */
  phone?: InputMaybe<Scalars["String"]>;
  /** User phone code */
  phonecode?: InputMaybe<Scalars["String"]>;
  /** User pin password */
  pin?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Status>;
  /** User referral code */
  usedReferralCode?: InputMaybe<Scalars["String"]>;
}

export interface UserFormated {
  __typename?: "UserFormated";
  /** User unique ID */
  _id: Scalars["ID"];
  achieved?: Maybe<Achieved>;
  claimcredit?: Maybe<Scalars["Boolean"]>;
  /** User Email */
  email?: Maybe<Scalars["String"]>;
  expiredFreemium?: Maybe<Scalars["DateTime"]>;
  /** User fullname */
  fullName?: Maybe<Scalars["String"]>;
  streakDate?: Maybe<Scalars["DateTime"]>;
}

export interface UserPaginateResponse {
  __typename?: "UserPaginateResponse";
  docs: User[];
  limit: Scalars["Int"];
  page: Scalars["Int"];
  totalDocs: Scalars["Int"];
  totalPages: Scalars["Int"];
}

export interface VerifyEmailInput {
  /** User email */
  email: Scalars["String"];
  /** Platform app OS */
  platform: Scalars["String"];
}

export interface VerifySmsInput {
  /** User country */
  country: Scalars["String"];
  /** User Phone Number */
  phone: Scalars["String"];
  /** User phone code */
  phonecode: Scalars["String"];
  /** Platform app OS */
  platform: Scalars["String"];
}

export interface VerifyTokenInput {
  /** Code sms o pin */
  code: Scalars["String"];
  /** verifyToken */
  verifyToken: Scalars["String"];
}

export interface VerifyUserResponse {
  __typename?: "VerifyUserResponse";
  user: User;
  verifyToken: Scalars["String"];
}

export type FindByIdActivityHistoryQueryVariables = Exact<{
  historyId: Scalars["ObjectId"];
}>;

export interface FindByIdActivityHistoryQuery {
  __typename?: "Query";
  findByIdActivityHistory: {
    __typename?: "Activityhistory";
    _id: string;
    activity?: { __typename?: "Activity"; description: string } | null;
    leadFriends?: Array<{
      __typename?: "LeadFriends";
      fullName?: string | null;
      email?: string | null;
      status?: string | null;
      response?: string | null;
    }> | null;
  };
}

export type ResponseByFriendMutationVariables = Exact<{
  historyId: Scalars["ObjectId"];
  index: Scalars["Int"];
  input: LeadsInput;
}>;

export interface ResponseByFriendMutation {
  __typename?: "Mutation";
  responseByFriend: boolean;
}

export const FindByIdActivityHistoryDocument = gql`
  query FindByIdActivityHistory($historyId: ObjectId!) {
    findByIdActivityHistory(historyId: $historyId) {
      _id
      activity {
        description
      }
      leadFriends {
        fullName
        email
        status
        response
      }
    }
  }
`;

/**
 * __useFindByIdActivityHistoryQuery__
 *
 * To run a query within a React component, call `useFindByIdActivityHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindByIdActivityHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindByIdActivityHistoryQuery({
 *   variables: {
 *      historyId: // value for 'historyId'
 *   },
 * });
 */
export function useFindByIdActivityHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindByIdActivityHistoryQuery,
    FindByIdActivityHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindByIdActivityHistoryQuery,
    FindByIdActivityHistoryQueryVariables
  >(FindByIdActivityHistoryDocument, options);
}
export function useFindByIdActivityHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindByIdActivityHistoryQuery,
    FindByIdActivityHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindByIdActivityHistoryQuery,
    FindByIdActivityHistoryQueryVariables
  >(FindByIdActivityHistoryDocument, options);
}
export type FindByIdActivityHistoryQueryHookResult = ReturnType<
  typeof useFindByIdActivityHistoryQuery
>;
export type FindByIdActivityHistoryLazyQueryHookResult = ReturnType<
  typeof useFindByIdActivityHistoryLazyQuery
>;
export type FindByIdActivityHistoryQueryResult = Apollo.QueryResult<
  FindByIdActivityHistoryQuery,
  FindByIdActivityHistoryQueryVariables
>;
export const ResponseByFriendDocument = gql`
  mutation ResponseByFriend(
    $historyId: ObjectId!
    $index: Int!
    $input: LeadsInput!
  ) {
    responseByFriend(historyId: $historyId, index: $index, input: $input)
  }
`;
export type ResponseByFriendMutationFn = Apollo.MutationFunction<
  ResponseByFriendMutation,
  ResponseByFriendMutationVariables
>;

/**
 * __useResponseByFriendMutation__
 *
 * To run a mutation, you first call `useResponseByFriendMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResponseByFriendMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [responseByFriendMutation, { data, loading, error }] = useResponseByFriendMutation({
 *   variables: {
 *      historyId: // value for 'historyId'
 *      index: // value for 'index'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResponseByFriendMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResponseByFriendMutation,
    ResponseByFriendMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResponseByFriendMutation,
    ResponseByFriendMutationVariables
  >(ResponseByFriendDocument, options);
}
export type ResponseByFriendMutationHookResult = ReturnType<
  typeof useResponseByFriendMutation
>;
export type ResponseByFriendMutationResult =
  Apollo.MutationResult<ResponseByFriendMutation>;
export type ResponseByFriendMutationOptions = Apollo.BaseMutationOptions<
  ResponseByFriendMutation,
  ResponseByFriendMutationVariables
>;
