/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useParams, useNavigate } from "react-router-dom";
import { Formik, Form, FormikProps } from "formik";
import * as Yup from "yup";
import {
  useFindByIdActivityHistoryQuery,
  useResponseByFriendMutation,
} from "../../generated/graphql";

import Intro from "../Intro";

interface IResponseForm {
  response: string;
}

const Home = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [tokenValid, setTokenValid] = useState<boolean>(false);
  const [historyId, setHistoryId] = useState<string>("");
  const [errorLogic, setErrorLogic] = useState<string>("");
  const [indexFriend, setIndexFriend] = useState<number>(0);
  const [responseByFriendMutation] = useResponseByFriendMutation();
  const { data, error, loading } = useFindByIdActivityHistoryQuery({
    variables: {
      historyId,
    },
  });

  const dataQuest = data?.findByIdActivityHistory?.leadFriends ?? [];

  useEffect(() => {
    if (token != null) {
      const splitData = token.split("-");
      if (splitData.length === 3) {
        setTokenValid(true);
        setHistoryId(splitData[0]);
        setIndexFriend(Number(splitData[1]));
      }
    }
  }, []);

  if (!tokenValid) {
    return <Intro />;
  }

  if (loading) {
    return <div>Cargando...</div>;
  }

  if (error != null || data == null) {
    return <div>Actividad no existe!!!</div>;
  }

  const Schema = Yup.object().shape({
    response: Yup.string().required("Ayudame respondiendo a la pregunta"),
  });

  const handleSubmit = async (
    values: IResponseForm,
    resetForm: Function,
    setSubmitting: Function,
  ) => {
    const leadFriends = dataQuest.map((item, index) => {
      if (index === indexFriend) {
        return {
          ...item,
          status: "success",
          response: values.response,
        };
      }
      return item;
    });
    try {
      if (values) {
        const responseByFriend = await responseByFriendMutation({
          variables: {
            historyId,
            index: indexFriend,
            input: { leadFriends },
          },
        });
        if (!responseByFriend?.data?.responseByFriend) {
          console.log("Ya respondiste a esta actividad");
          setErrorLogic("Ya respondiste a esta actividad");
        } else {
          resetForm({});
          navigate("/success");
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{
        response: "",
      }}
      onSubmit={(values: IResponseForm, actions) => {
        void handleSubmit(values, actions.resetForm, actions.setSubmitting);
      }}
      validationSchema={Schema}
    >
      {(props: FormikProps<IResponseForm>) => {
        const {
          values,
          touched,
          errors,
          handleBlur,
          handleChange,
          isSubmitting,
        } = props;
        return (
          <Form className="containerform">
            <Box className="wrapper">
              <Typography className="quest">
                {data?.findByIdActivityHistory?.activity?.description}
              </Typography>
              {errorLogic !== "" && (
                <Typography className="error">{errorLogic}</Typography>
              )}
              <TextField
                margin="normal"
                required
                fullWidth
                id="response"
                label="Escribelo aquí…"
                name="response"
                autoFocus
                multiline
                maxRows={4}
                variant="standard"
                placeholder=""
                value={values.response}
                helperText={
                  errors.response && touched.response
                    ? errors.response
                    : "Responde y luego envia"
                }
                error={!!(errors.response && touched.response)}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Box>

            <Button
              type="submit"
              disabled={isSubmitting}
              variant="contained"
              size="large"
              disableElevation
              className="response"
              style={{
                marginTop: 60,
                textTransform: "none",
                fontSize: 18,
              }}
            >
              {isSubmitting ? "Enviando..." : "Enviar Respuesta"}
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Home;
