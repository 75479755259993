/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";

import IIntro from "../assets/images/icon_intro.png";
import IApple from "../assets/images/apple.png";
import IAndroid from "../assets/images/android.png";

const Intro = () => {
  return (
    <>
      <img
        src={IIntro}
        alt="Logo"
        style={{ width: 283, height: 204, marginTop: 30 }}
      />
      <Typography className="title-success">
        {"¡Bienvenido a Sublime!"}
      </Typography>
      <Typography className="subtitle-success">
        {
          "Este es el sitio para ayudar a tus amigos a completar caminos sublimes"
        }
      </Typography>
      <Typography className="title-bottom">
        {"¡Únete a una experiencia Sublime!"}
      </Typography>
      <Grid
        container
        justifyContent="space-around"
        alignItems="center"
        style={{ marginTop: 30, marginBottom: -70 }}
      >
        <Grid justifyContent="center" alignItems="center">
          <Button
            variant="contained"
            size="large"
            disableElevation
            className="stores"
            style={{
              textTransform: "none",
              fontSize: 18,
            }}
            startIcon={
              <img src={IApple} alt="Logo" style={{ width: 18, height: 20 }} />
            }
          >
            iPhone
          </Button>
        </Grid>
        <Grid justifyContent="center" alignItems="center">
          <Button
            variant="contained"
            size="large"
            disableElevation
            className="stores"
            style={{
              textTransform: "none",
              fontSize: 18,
            }}
            startIcon={
              <img
                src={IAndroid}
                alt="Logo"
                style={{ width: 18, height: 20 }}
              />
            }
          >
            Android
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default Intro;
