/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import LayoutComponent from "./components/Layout";
import Home from "./pages/Home/index";
import Success from "./pages/Success";
import Intro from "./pages/Intro";

const App: React.FunctionComponent = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LayoutComponent />}>
          <Route index element={<Intro />} />
          <Route path=":token" element={<Home />} />
          <Route path="success" element={<Success />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
