/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { Outlet } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import Logo from "../assets/images/Logo.png";

const Copyright = (props: any) => {
  return (
    <Typography align="center" {...props}>
      {"Al ingresar en Sublime estás aceptando nuestros "}
      <Link target="_blank" color="inherit" href="https://soysublime.com/">
        Términos y Condiciones
      </Link>
      {" Todos los derechos reservados. © "}
      {new Date().getFullYear()}
      {"."}
      <br></br>
      {"Versión 1.0.9"}
    </Typography>
  );
};

const theme = createTheme({
  typography: {
    fontFamily: [
      "Poppins",
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
  palette: {
    primary: {
      main: "#64748B",
    },
  },
});

const LayoutComponent: React.FunctionComponent = () => {
  return (
    <ThemeProvider theme={theme}>
      <div className="bg">
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className="container">
            <img src={Logo} alt="Logo" style={{ width: 200, height: 160 }} />
            <Outlet />
          </div>
          <Copyright
            style={{
              color: "#181E38",
              opacity: 0.5,
              fontSize: 12,
              marginTop: "30%",
            }}
          />
        </Container>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={false}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </ThemeProvider>
  );
};

export default LayoutComponent;
